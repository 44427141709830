import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "picker", "hiddenInput"];

  connect() {
    this.showPicker = false;
    document.addEventListener("click", this.handleOutsideClick.bind(this));
    
    this.autoSelectExistingEmoji();

    this.inputTarget.addEventListener("click", this.handleInputClick.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this));
    this.inputTarget.removeEventListener("click", this.handleInputClick.bind(this));
  }

  handleInputClick(event) {
    event.stopPropagation();
    
    this.togglePicker(event);
  }

  autoSelectExistingEmoji() {
    const existingEmojiName = this.hiddenInputTarget.value;
    
    if (!existingEmojiName) return;

    let emojiButton = this.pickerTarget.querySelector(`[data-emoji-name="${existingEmojiName}"]`);

    if (!emojiButton) {
      emojiButton = this.pickerTarget.querySelector(`[data-emoji-raw][data-emoji-name="${existingEmojiName}"]`);
    }

    if (emojiButton) {
      this.selectEmoji({ 
        stopPropagation: () => {}, 
        currentTarget: emojiButton 
      });
    }
  }

  togglePicker(event) {
    event.stopPropagation();
    this.showPicker = !this.showPicker;
    this.pickerTarget.classList.toggle("hidden", !this.showPicker);
  }

  selectEmoji(event) {
    event.stopPropagation();

    const emoji_image = event.currentTarget.dataset.emojiImage;
    const emoji_name = event.currentTarget.dataset.emojiName;
    const emoji_raw = event.currentTarget.dataset.emojiRaw;

    const emojiContainer = this.inputTarget.querySelector('.emoji-container');

    if (emojiContainer) {
      emojiContainer.remove();
    }

    const container = document.createElement('span');
    container.classList.add('emoji-container', 'inline-block', 'ml-0', 'pl-0');

    if (emoji_image) {
      const img = document.createElement('img');
      img.src = emoji_image;
      img.alt = emoji_name;
      img.className = 'w-4 h-4 inline-block';
      container.appendChild(img);
    } else if (emoji_raw) {
      container.textContent = emoji_raw;
    }

    const iconSpan = this.inputTarget.querySelector('[data-icon="mdi:emoji-outline"]');
    iconSpan.insertAdjacentElement('afterend', container);

    this.hiddenInputTarget.value = emoji_name;

    if (event.currentTarget) {
      this.togglePicker(event);
    }
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.showPicker = false;
      this.pickerTarget.classList.add("hidden");
    }
  }
}